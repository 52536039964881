<template>
  <div class="clientHome" ref="clientHome" :style="{'min-height': isIpad}">
    <!--    <div class="cover" v-if="cover" @click="closeCover">-->
    <!--      <img v-if="!isAliApp" class="cover-img"-->
    <!--           :src="`https://cdn.cailu88.com/jingxuanshi/client_home_${this.terminal == 'android' ? 'guide%20' : 'guide_safari'}.png`"/>-->

    <!--      <div v-else>-->
    <!--        <img class="cover-img-ali" :src="'https://cdn.cailu88.com/jingxuanshi/client_cover_and2@2x.png'"/>-->
    <!--        <img class="cover-img-button" :src="'https://cdn.cailu88.com/jingxuanshi/client_cover_button@2x.png'"-->
    <!--             @click="closeCover"/>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="mark" v-if="!click">
      <van-loading type="spinner" vertical>加载中...</van-loading>
    </div>
    <div class="footer-fix" v-if="isAliApp">
      <div>
        <van-button class="button" @click="handleCopy">复制链接至浏览器下载鲸选师</van-button>
      </div>
    </div>
    <div class="top">
      <!-- <div>{{ua}}</div> -->
      <img class="top-logo" src="https://cdn.cailu88.com/jingxuanshi/client_home_logo_2.png"/>
      <div class="top-left"></div>
    </div>
    <div class="empty-box">
      <!--      <van-button class="button" type="default" @click="handleDownload">{{ buttonText }}</van-button>-->
      <van-button class="button" id="download" type="default" @click="openApp">打开APP</van-button>
    </div>
    <div class="bottom" :style="{'position' : (height ? 'absolute' : '')}">
      <img class="bottom-phone" src="https://cdn.cailu88.com/jingxuanshi/client_home_phone_2@2x.png"/>
    </div>
    <!--    <div class="footer">-->
    <!--      <p class="pic-text">-->
    <!--        <span>Copyright © 2019-2020 杭州聚心选网络科技有限公司版权所有</span><br/>-->
    <!--        <a href="http://www.beian.gov.cn/portal/index.do" target="view_window">浙ICP备20004143号</a>-->
    <!--      </p>-->
    <!--    </div>-->
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Loading, Toast } from 'vant'

Vue.use(Button)
Vue.use(Loading)
Vue.use(Toast)

export default {
  name: 'clientHome',
  data () {
    return {
      ua: {},
      click: true,
      cover: false,                 // 遮罩
      isAliApp: false,              // 支付宝小程序下安卓环境
      isWx: false,
      isIpad: '100vh',
      terminal: '',
      // currentEnvironment: '',
      height: true,
      //  安卓安装包url
      downloadUrl: 'https://cdn.cailu88.com/jingxuanshi/apk/jxs_release_0.6.1.apk',
      //  微信应用宝下载地址（可以下载Android和iOS）
      weixinUrl: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.duilu.jxs',
      //  appstore
      appsUrl: 'https://apps.apple.com/cn/app/id1533950475',
      buttonText: '立即下载',
    }
  },
  mounted () {
    const script = document.createElement('script')
    script.src = 'https://g.alicdn.com/jssdk/u-link/index.min.js'
    script.language = 'JavaScript'
    document.body.appendChild(script)
    // this.isWX()
    this.screenWidth()
    window.addEventListener('resize', this.screenWidth)
    // 通过$on,$once使用hook监听vue生命周期函数，进行监听销毁
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.screenWidth)
    })
  },
  methods: {
    // 立即下载
    handleDownload () {
      const u = window.navigator.userAgent
      // ios终端
      const isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      // android
      const isAndroidApp = (u.indexOf('Android') > -1)
      if (isIOSApp) {
        this.terminal = 'ios'
        // this.buttonText = '敬请期待';
        window.open(this.appsUrl)
        return
      } else if (isAndroidApp) {
        if (this.isAliApp) {
          this.handleCopy()
        } else if (this.isWx) {
          window.open(this.weixinUrl)
        } else {
          this.terminal = 'android'
          window.open(this.downloadUrl)
        }
      }
    },
    openApp () {
      const cParams = ULink.getUriParams().c
      ULink([{
        id: 'usr1f3p1nlg6ola2', /* 平台为每个应用分配的方案Ulink ID，必填 */
        data: {
          'c': cParams,
          // 'custom': '{"relationType":3,"relationUrl":"https%3A%2F%2Fm.sandbox.jingxuanshi.net%2Fpacket%2Fchoose%3FinviteCode%3DJXSA3JQ7%26taskId%3D6"}'
        },
        selector: '#download',
        auto:true,
        useOpenInBrowerTips: 'default',
        proxyOpenDownload: function (defaultAction, LinkInstance) {
          if (LinkInstance.solution.type === 'scheme') {
            // qq或者微信环境特殊处理下
            if (ULink.isWechat || ULink.isQQ) {
              // 在qq或者微信环境执行内置逻辑，具体内置逻辑为:当设置了useOpenInBrowerTips字段时，qq&&微信&&scheme时，启用蒙层提示去浏览器打开
              defaultAction()
            } else {
              window.location.href = LinkInstance.solution.downloadUrl
            }
          } else if (LinkInstance.solution.type === 'universalLink') {
            // universalLink 唤起应当由服务端提供一个带重定向到appstore的universallink地址。因此，此处不应写逻辑，友盟会在近期上线universalLink 重定向功能。
          }
        },
      }])
    },
    screenWidth () {
      this.isIpad = '100vh'
      let width = document.documentElement.clientWidth
      let height = document.documentElement.clientHeight
      if (height < 600) {
        this.height = false
      } else {
        this.height = (width < 350 && width > 310) || (width > 365 && height < 680) ? false : true
      }
      if (height > 600 && width > 500) {
        this.height = false
      }
    },

    // 复制链接
    handleCopy () {
      this.$copyText(this.downloadUrl).then(() => {
        Toast.success('复制成功')
      }, () => {
        Toast.fail('复制失败')
      })
    },

    // 判断是否在微信环境
    // isWX () {
    //   const u = window.navigator.userAgent
    //   this.ua = u
    //   // ios终端
    //   const isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    //   // android
    //   const isAndroidApp = (u.indexOf('Android') > -1)
    //   if (/MicroMessenger/gi.test(u)) {
    //     // 微信
    //     this.cover = isAndroidApp ? true : false
    //     this.isWx = true
    //   } else if (/MQQBrowser/gi.test(u) && isAndroidApp) {
    //     // qq安卓
    //     if (/QQ/gi.test(u.split('MQQBrowser'))) {
    //       this.cover = true
    //     } else {
    //       this.cover = false
    //     }
    //   } else if (/QQ/gi.test(u) && isIOSApp) {
    //     // qq ios
    //     this.cover = false
    //   } else if (/AliApp/gi.test(u)) {
    //     this.cover = true
    //     if (isAndroidApp) {
    //       this.isAliApp = true
    //       this.buttonText = '获取下载链接'
    //     } else if (isIOSApp) {
    //       this.cover = false
    //     }
    //   }
    //   this.terminal = isIOSApp ? 'ios' : isAndroidApp ? 'android' : ''
    // },

    // 收起遮罩
    closeCover () {
      this.cover = false
    }
  }
}
</script>
<style lang="less" scoped>
.clientHome {
  position: relative;
  width: 100%;
  height: 100vh;
  text-align: center;
  background: #F8E94E;

  .cover {
    position: absolute;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.8);
    width: 100%; /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
    height: 100%;
    filter: alpha(opacity=100); /*设置透明度为100%*/
    opacity: 1; /*非IE浏览器下设置透明度为100%*/
    display: block;
    z-Index: 999;

    .cover-img {
      width: 100%;
    }

    .cover-img-ali {
      margin-top: 10px;
      width: 302px;
    }

    .cover-img-button {
      margin-top: 31px;
      width: 176px;
    }
  }

  .footer-fix {
    position: fixed;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    div {
      .button {
        width: 300px;
        background: #FFEA1C;
        box-shadow: 0px 2px 4px 0px #FFEA1C;
        border-radius: 25px;
        border: none;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
      }
    }
  }

  .mark {
    width: 160px;
    height: 80px;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
  }

  .top {
    position: relative;
    padding-top: 8px;

    .top-logo {
      width: 100%;
    }

    .top-left {
      position: absolute;
      right: 0;
      top: 0;
      width: 79px;
      height: 89px;
      background: linear-gradient(229deg, #FDC005 0%, #FA8C02 100%);
      opacity: 0.5;
      filter: blur(19px);
    }
  }

  .empty-box {
    width: 100%;
    height: 80px;
  }

  .button {
    margin: 15px auto;
    width: 240px;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    background: #FFFFFF;
    border-radius: 25px;
    border: none;
  }

  .bottom {
    //position: absolute;
    bottom: 0;
    width: 100%;
    line-height: 0;

    .bottom-phone {
      width: 100%;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #F8E94E;

    .pic-text {
      text-align: center;
      font-size: 10px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #291306;
      line-height: 20px;

      & > a {
        color: #AF7018;
        text-decoration: none;
      }

      & > a:hover {
        cursor: pointer;
        color: black;
      }
    }
  }
}
</style>
